<template>
    <div id="app">
      <DisableAdd v-if="!id && !isLoaded" ref="simpleRules" :key="$route.fullPath" :is-loaded="isLoaded" :hotel-details="hotelDetails" @on-Submit="disableHotel" />
      <DisableAdd v-if="id && isLoaded" ref="simpleRules" :key="$route.fullPath" :is-loaded="isLoaded" :hotel-details="hotelDetails" @on-Submit="updateDisabledHotel" />
  
    </div>
  </template>
  
  <script>
  import checkLoginRole from '@/helpers/checkLoginRole'
  import hotelContentService from '@/services/hotel-content/hotelContent.service'
  import { resConditionCheck } from '@/helpers/constant'
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import errorResponseHandler from '@/services/errorHandler'
  
  import DisableAdd from './DisableAdd.vue'
  
  export default {
    name: 'DisableAddEdit',
    components: { DisableAdd },
    data() {
      return {
        isLoaded: false,
        hotelDetails: {},
        id: this.$route.params.id ? this.$route.params.id : '',
        checkAccess: {},
        checkLoginRole
  
      }
    },
    watch: {
      async $route() {
        if (this.$route.name === 'disabled-hotel-add') {
          this.id = ''
          this.hotelDetails = {}
          this.isLoaded = false
        } else if (this.$route.name === 'disabled-hotel-edit') {
          this.id = this.$route.params.id
          let data = await this.getHotelById(this.$route.params.id)
          console.log("data", data)
          this.isLoaded = true
        }
      }
    },
  
    async mounted() {
      if (this.$route.params.id) {
        await this.getHotelById(this.$route.params.id)
      }
    },
  
    methods: {
      async disableHotel(hotelDisableAddForm) {
        const valid = await this.$refs.simpleRules.validationForm()
        if (valid) {          
          this.$refs.simpleRules.loading = true
          const { ...hotelData } = hotelDisableAddForm;

          await hotelContentService.newDisableHotel(hotelData).then(res => {
            this.$refs.simpleRules.loading = false

            if (resConditionCheck(res.status) && res.data.data) {
              this.$router.push({ name: 'disabled-hotel-list' })
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
          }).catch(error => {
            this.$refs.simpleRules.loading = false
            const errorData = errorResponseHandler(error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger",
              },
            });
          })
        }
      },
  
      async getHotelById(id) {
        await hotelContentService.getDisableHotel(id).then(res => {
          this.isLoaded = true;
          if (resConditionCheck(res.status) && res.data.data) {
            this.hotelDetails = res.data.data
          }
        }).catch(error => {
          const errorData = errorResponseHandler(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        })
      },
  
      async updateDisabledHotel(hotelDisableAddForm) {
        const valid = await this.$refs.simpleRules.validationForm()
        if (valid) {
          this.$refs.simpleRules.loading = true
          const { ...hotelData } = hotelDisableAddForm;

          hotelContentService.editDisabledHotel(this.$route.params.id, hotelData).then(res => {
            this.$refs.simpleRules.loading = false

            if (resConditionCheck(res.status) && res.data.data) {
              this.$router.push('/disabled-hotels/list')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
          }).catch(error => {
            this.$refs.simpleRules.loading = false
            const errorData = errorResponseHandler(error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger",
              },
            });
          })
        }
      }
    }
  }
  
  </script>
  