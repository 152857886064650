<template>
    <b-card-code>
      <!-- forms -->
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent="$emit('on-Submit', HotelDisableAddForm)">
          <b-row col>
            <b-col md="6">
              <b-form-group label="Hotel Supplier ID" label-for="mc-hotel-supplier-id">
                <validation-provider
                  #default="{ errors }"
                  name="Hotel Supplier ID"
                  rules="required"
                >
                  <b-form-input
                    v-model="HotelDisableAddForm.hotel_supplier_id"
                    class="hotel_supplier_id"
                    :readonly="isLoaded"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Hotel Supplier ID"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Hotel Name" label-for="hotel_name">
                <validation-provider
                  #default="{ errors }"
                  name="Hotel Name"
                rules="required"
                >
                  <b-form-input
                    v-model="HotelDisableAddForm.name"
                    class="hotel_name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Hotel Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
  
          <b-row col>
            <b-col md="6">
              <b-form-group label="Hotel Supplier Code" label-for="mc-hotel-supplier-code">
                <validation-provider
                  #default="{ errors }"
                  name="Hotel Supplier Code"
                  rules="required"
                >
                  <b-form-input
                    v-model="HotelDisableAddForm.supplier_code"
                    class="hotel_supplier_code"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Hotel Supplier Code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Resort"
                  rules="required"
                >
                  <b-form-group
                    label="Resort"
                    label-for="mc-resort"
                    :state="errors.length > 0 ? false:null"
                  >
                    <v-select
                      v-model="HotelDisableAddForm.resort"
                      :options="DestinationList"
                      class="destination"
                      placeholder="Select Resort"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
            </b-col>
          </b-row>
  
          <!-- submit button -->
          <b-col md="12" class="demo-inline-spacing">
            <b-button :disabled="loading" variant="primary" type="submit">
              Submit
              <div v-if="loading" class="spinner-border spinner-border-sm" />
            </b-button>
            <b-button
              variant="secondary"
              :to="{
                name:
                  checkLoginRole() === RoleEnum.SuperAdmin || checkAccess.view
                    ? 'disabled-hotel-list'
                    : 'dashboard'
              }"
            >
              Cancel
            </b-button>
          </b-col>
          <!-- </b-row> -->
        </b-form>
      </validation-observer>
    </b-card-code>
  </template>
  
  <script>
  import BCardCode from "@core/components/b-card-code";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import vSelect from 'vue-select'
  import {
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BAlert
  } from "bootstrap-vue";
  import { required, alpha } from "@core/utils/validations/validations";
  import checkLoginRole from "@/helpers/checkLoginRole";
  import { RoleEnum, resConditionCheck } from "@/helpers/constant";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import accessRightCheck from "@/helpers/accessRightCheck";
  import BookingService from '@/services/booking/booking.service';
  import errorResponseHandler from '@/services/errorHandler';
  
  export default {
    components: {
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      BFormInput,
      BFormGroup,
      BForm,
      BRow,
      BCol,
      BButton,
      BAlert,
      // BFormCheckbox,
      // BMediaBody,
      BFormCheckbox,
      vSelect,
    },
  
    props: {
      hotelDetails: {
        type: Object,
        required: true
      },
      isLoaded: {
        type: Boolean,
        required: true
      }
    },
  
    data() {
      return {
        HotelDisableAddForm: {
          hotel_supplier_id: this.hotelDetails?.hotelSupplierId || "",
          name: this.hotelDetails?.name || "",
          supplier_code: this.hotelDetails?.supplierCode || "",
          resort: this.hotelDetails?.resort || "",
        },
        required,
        alpha,
        checkLoginRole,
        RoleEnum,
        loading: false,
        staticLink: "",
        checkAccess: {},
        DestinationList: [],
      };
    },
  
    mounted() {
      // if (!this.$route.params.id) {
      //   this.HotelDisableAddForm.is_active = true;
      // }
      this.checkAccess = accessRightCheck(this.$route.meta.module.name);
      this.getHotelDestination()
    },
  
    methods: {
    
      async getHotelDestination() {
        await BookingService.getHotelDestinationsList().then(res => {
          if (resConditionCheck(res.status) && res.data) {
            this.DestinationList = res.data.data.map(e => ({
              label: `${e.name}, ${e.country}`,
              value: e.name,
            }));
          }
        }).catch(error => {
          console.error("Errrororororor",error)
          errorResponseHandler(error)
        })
      },

      async validationForm() {
        let valid = null;
        await this.$refs.simpleRules.validate().then(success => {
          if (success) {
            valid = true;
          } else {
            valid = false;
          }
        });
        return valid;
      }
    }
  };
  </script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>

  <style lang="scss">
  .dropBtn {
    background-color: #d3394c;
    border: 0;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    padding: 15px 35px;
    position: relative;
  }
  
  .dropBtn:hover {
    background-color: #722040;
  }
  
  .align-center {
    text-align: center;
  }
  
  // .helper {
  //   height: 100%;
  //   display: inline-block;
  //   vertical-align: middle;
  //   width: 0;
  // }
  
  .hidden {
    display: none !important;
  }
  
  .display-inline {
    display: inline-block;
    vertical-align: middle;
  }
  
  </style>
  